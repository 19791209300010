'use client';

import { MarkerIO } from '@/components/MarkerIO';
import { Sticky } from '@/components/global/Sticky';
import { CustomError } from '@/components/CustomError';
import { versions } from '@themaven-net/unified-config';
import { Header } from '@/components/raven/global/Header';
import { Footer } from '@/components/raven/global/Footer';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import { useFetchAppSettings } from '@/utils/hooks/use-fetch-app-settings';

function GlobalError({ reset }: { reset: () => void }) {
	const appSettings = useFetchAppSettings();

	const menus = appSettings?.menus || {};
	const settings = (appSettings?.settings as AppSettings['settings']) || {};
	const markerIOProjectId = settings?.integrations?.marker_io?.project_id;
	const designTokenVersion = versions.designToken;
	const typekitUrl = settings?.integrations?.typekit?.url;

	return (
		<html lang="en">
			<head>
				{typekitUrl && <link href={typekitUrl} rel="stylesheet" />}
				<link href={`/style/${designTokenVersion}.css`} rel="stylesheet" />
			</head>
			<body>
				<Sticky>
					<Header menus={menus} settings={settings} />
				</Sticky>
				<main className="error-page" id="main">
					<CustomError
						errorPageCode=""
						errorPageTitle="Oops! Something went wrong"
						image={settings?.error_page_image}
					>
						<p>There has been a problem loading this page.</p>
						<button
							className="button button--outline"
							onClick={() => reset()}
							type="button"
						>
							Try again
						</button>
					</CustomError>
				</main>
				<Footer
					copyright={settings?.copyright}
					logo={settings?.footer_logo}
					menus={menus}
				/>
				<MarkerIO projectId={markerIOProjectId} />
			</body>
		</html>
	);
}

export default GlobalError;
